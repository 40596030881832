import React from "react";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Loader } from "../ui/loader";

export interface BookingButtonSkeletonsProps {
  number?: number;
}

export const BookingButtonSkeletons = ({ number }: BookingButtonSkeletonsProps) => (
  <>
    {new Array(number).fill(0).map((number) => (
      <React.Fragment key={number}>
        <div className="booking-button booking-button--loading">
          <Loader />
        </div>
        <HorizontalSpacer />
      </React.Fragment>
    ))}
  </>
);
