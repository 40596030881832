import type { BookingType } from "@/components/booking/types";
import { type BookingButtonFragment, OrderItemTypes } from "@/generated/client.generated";
import { useBooking } from "@/hooks/use-booking";
import { useServiceBooking } from "@/hooks/use-service-booking";
import { type MyGroupActivityBookingTypes, isBookable } from "@/utils/booking-utils";
import { paths } from "@/utils/paths-utils";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { BookingButton, type BookingButtonUsecase } from "./booking-button";

function getUrl(
  bookingType: BookingType,
  activities: ReadonlyArray<string>,
  bookingId: string,
  centerId?: string,
): string {
  const infoPageForBookingType = paths.infoPageForBookingType(bookingType);

  if (activities.length > 0) {
    return paths.gruppetime(
      activities[0],
      centerId
        ? {
            bookingId: bookingId,
            centerId: centerId,
          }
        : undefined,
    );
  }
  if (infoPageForBookingType) {
    return infoPageForBookingType;
  }
  return paths.gruppetimer;
}

interface BookingButtonWrapperProps {
  bookingData?: MyGroupActivityBookingTypes;
  parts: BookingButtonFragment;
  showFullDate: boolean;
  bookingType: BookingType;
  isCancelled: boolean;
}

export const BookingButtonWrapper = ({
  bookingData,
  parts,
  showFullDate,
  bookingType,
  isCancelled = false,
}: BookingButtonWrapperProps) => {
  const url = getUrl(
    bookingType,
    parts.activities.map((activity) => activity.slug),
    parts.id,
    parts.center?.id,
  );
  // For group activities that cost money we need to use a service order flow
  if (parts.GroupActivity && parts.GroupActivity.price.amount > 0 && !bookingData) {
    return (
      <BookingButtonPayableGroupActivity
        parts={parts}
        showFullDate={showFullDate}
        bookingType={bookingType}
        url={url}
      />
    );
  }
  return (
    <BookingButtonGroupActivity
      bookingData={bookingData}
      parts={parts}
      showFullDate={showFullDate}
      bookingType={bookingType}
      url={url}
      isCancelled={isCancelled}
    />
  );
};

interface BookingButtonGroupActivityProps {
  bookingData?: MyGroupActivityBookingTypes;
  parts: BookingButtonFragment;
  showFullDate: boolean;
  bookingType: BookingType;
  url: string;
  isCancelled: boolean;
}

const BookingButtonGroupActivity = ({
  bookingData,
  parts,
  showFullDate,
  bookingType,
  url,
  isCancelled,
}: BookingButtonGroupActivityProps) => {
  const { handleBooking, handleCancelBooking, loading, booking, bookingError, cancelError } = useBooking(
    parts.id,
    bookingData,
  );

  const bookable = isBookable(parts, isCancelled);

  let useCase: BookingButtonUsecase = {
    cannotBeBookedReason: isCancelled ? "CANCELLED" : bookable.code,
    type: "Unavailable",
  };

  if (!isCancelled) {
    if (booking) {
      const sharedBookingDetails = {
        calendarEvent: {
          description: parts.GroupActivity?.page.metaDescription ?? "",
          end: parts.end,
          location: parts.center?.page.title ?? "",
          start: parts.start,
          summary: parts.name,
        },
        loading,
      };

      if (booking.__typename === "GroupActivityBooking") {
        useCase = {
          ...sharedBookingDetails,
          availableSpots: booking.BookableGroupActivity.slot.available,
          canBeCancelled: booking.cancel.isEnabled,
          cannotBeCancelledReason: booking.cancel.code,
          onClickCancel: handleCancelBooking,
          type: "Booked",
        };
      } else if (booking.__typename === "WaitingListBooking") {
        useCase = {
          ...sharedBookingDetails,
          onWithdrawFromWaitingList: handleCancelBooking,
          type: "OnWaitinglist",
          waitingListPosition: booking.waitingListPosition,
        };
      }
    } else if (parts.slot.hasWaitingList && parts.slot.available === 0) {
      useCase = {
        loading: loading,
        onClickWaitinglist: handleBooking,
        type: "WaitingList",
        waitingListQueueLength: parts.slot.inWaitingList,
      };
    } else if (!bookable.isEnabled && bookable.code) {
      useCase = {
        cannotBeBookedReason: bookable.code,
        type: "Unavailable",
      };
    } else if (bookable.isEnabled) {
      useCase = {
        availableSpots: parts.slot.available,
        canBeCancelled: parts.cancel.isEnabled,
        cannotBeCancelledReason: parts.cancel.code,
        loading: loading,
        onClickBook: handleBooking,
        priceAmount: parts.GroupActivity?.price.amount ?? 0,
        type: "Bookable",
      };
    }
  }

  return (
    <BookingButton
      usecase={useCase}
      center={parts.center?.page.title}
      endDate={parts.end}
      error={booking ? cancelError : bookingError}
      notice={parts.externalMessage ?? undefined}
      imgSrc={parts.GroupActivity?.page?.thumbnailImage?.url}
      name={parts.name}
      personOrResourceSubtitle={parts.instructors.length > 0 ? `med ${parts.instructors[0].page.title}` : undefined}
      showFullDate={showFullDate}
      startDate={parts.start}
      url={url}
      bookingType={bookingType}
    />
  );
};

interface BookingButtonPayableGroupActivityProps {
  parts: BookingButtonFragment;
  showFullDate: boolean;
  bookingType: BookingType;
  url: string;
}

const BookingButtonPayableGroupActivity = ({
  parts,
  showFullDate,
  bookingType,
  url,
}: BookingButtonPayableGroupActivityProps) => {
  const router = useRouter();
  const {
    handleBooking: handleServiceBooking,
    loading: serviceLoading,
    booking: serviceBooking,
    error: serviceError,
  } = useServiceBooking({});
  const [serviceBookingButtonClicked, setServiceBookingButtonClicked] = useState<boolean>(false);
  const bookable = isBookable(parts);

  useEffect(() => {
    if (!serviceError && serviceBooking && serviceBookingButtonClicked) {
      router.push(paths.bookingSummary(serviceBooking.id));
    }
  }, [serviceBookingButtonClicked, serviceError, serviceBooking, router]);

  let useCase: BookingButtonUsecase = {
    cannotBeBookedReason: bookable.code,
    type: "Unavailable",
  };

  if (parts.slot.hasWaitingList && parts.slot.available === 0) {
    useCase = {
      loading: serviceLoading,
      onClickWaitinglist: () =>
        handleServiceBooking(
          [
            {
              amount: parts.GroupActivity?.price ?? null,
              birthDate: undefined,
              code: null,
              productId: parts.id,
              receiverDetails: null,
              resourceId: null,
              senderDetails: null,
              start: null,
              type: OrderItemTypes.Groupactivity,
            },
          ],
          parts.center?.id,
        ),
      type: "WaitingList",
      waitingListQueueLength: parts.slot.inWaitingList,
    };
  } else if (bookable.isEnabled) {
    useCase = {
      availableSpots: parts.slot.available,
      canBeCancelled: parts.cancel.isEnabled,
      cannotBeCancelledReason: parts.cancel.code,
      loading: serviceLoading,
      onClickBook: async () => {
        await handleServiceBooking(
          [
            {
              amount: null,
              birthDate: undefined,
              code: null,
              productId: parts.id,
              receiverDetails: null,
              resourceId: null,
              senderDetails: null,
              start: null,
              type: OrderItemTypes.Groupactivity,
            },
          ],
          parts.center?.id,
        );
        setServiceBookingButtonClicked(true);
        return Promise.resolve();
      },
      priceAmount: parts.GroupActivity?.price.amount ?? 0,
      type: "Bookable",
    };
  }
  //default unavailable
  // For group activities that cost money we need to user a service order flow
  return (
    <BookingButton
      usecase={useCase}
      center={parts.center?.page.title}
      endDate={parts.end}
      name={parts.name}
      personOrResourceSubtitle={parts.instructors.length > 0 ? `med ${parts.instructors[0].page.title}` : undefined}
      startDate={parts.start}
      bookingType={bookingType}
      imgSrc={parts.GroupActivity?.page?.thumbnailImage?.url}
      showFullDate={showFullDate}
      error={serviceError}
      url={url}
    />
  );
};
